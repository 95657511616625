@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --primary-color: #341148;
  --secondary-color: #63196b;
  --tertiary-color: #ffe6f0;
  --background-color: #341148;
  --highlight-color: #602a6d;
  --alternative-color: #ebccf0;
  --primary-text-color: #341148;
  --primary-font: "Montserrat", sans-serif;
  --secondary-font: "Playfair Display", system-ui;
  --primary-font-size: 18px;
  --secondary-font-size: 32px;
}
body {
  margin: 0;
  font-family: var(--primary-font);
  font-weight: 450;
  font-size: var(--primary-font-size);
  color: var(--primary-text-color);
  line-height: 150%;
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  color: inherit;
  margin-block: 0;
  line-height: 120%;
}
section {
  overflow: hidden;
  width: -webkit-fill-available;
}

.m16 {
  margin: 16px;
}
h1.white,
h2.white,
h3.white,
h4.white,
h5.white,
h6.white,
p.white,
a.white {
  color: var(--tertiary-color) !important;
}

h1.overflow,
h2.overflow,
h3.overflow,
h4.overflow,
h5.overflow,
h6.overflow,
p.overflow {
  width: -webkit-fill-available;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
}

.divider {
  display: block;
  height: 1.5px;
  width: -webkit-fill-available;
  background-color: var(--alternative-color);
}

.divider.dark {
  background-color: var(--background-color);
}

.section {
  position: relative;
  display: flex;
  padding: 64px 144px;
  gap: 32px;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: column;
  align-content: flex-start;
}

.section.white {
  background-color: var(--tertiary-color);
}

.section.secondary {
  background-color: #53165f;
}
/* Navbar */
.navbar {
  position: relative;
  display: flex;
  z-index: 1;
  padding: 4px 144px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color);
}

.navbar .navbar-brand {
  width: 188px;
  height: 88px;
}

.navbar .navbar-brand,
.navbar .navbar-menu .dropdown-menu .dropdown-item,
.navbar .navbar-menu .nav-item {
  position: relative;
  display: flex;
  text-decoration: none;
  line-height: 150%;
  font-weight: 450;
  transition: ease-in-out 0.3s;
  font-family: var(--primary-font);
  color: var(--tertiary-color);
  font-size: var(--primary-font-size);
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navbar .navbar-menu .nav-item.active {
  font-weight: 600;
  text-decoration: underline;
}

.navbar .navbar-menu {
  position: relative;
  display: flex;
}

.navbar .navbar-menu .dropdown-menu {
  position: absolute;
  display: flex;
  min-width: 240px;
  overflow: hidden;
  flex-wrap: nowrap;
  border-radius: 4px;
  align-items: stretch;
  align-content: stretch;
  flex-direction: column;
  background-color: var(--highlight-color);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-menu .dropdown-menu .dropdown-item,
.navbar .navbar-menu .nav-item {
  padding: 18px 24px;
  font-weight: 550;
  text-decoration: none;
}

.navbar .navbar-menu .dropdown-menu .dropdown-item {
  font-weight: 500;
  text-decoration: underline;
}

.navbar .navbar-menu .dropdown-menu .dropdown-item:hover,
.navbar .navbar-menu .dropdown-menu .dropdown-item:focus {
  background-color: #00000021;
}
/* Hero */
.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 32px;
  padding: 180px 128px 0 128px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: linear-gradient(248deg, #63196be0, #53165fa1),
    url(https://cdn.prod.website-files.com/668ab66e9c5572bfad197e28/668ab69a160abcbd3332b3fc_mountain_view.jpg);
}

.hero-title {
  font-family: var(--secondary-font);
  font-weight: 700;
  font-size: 96px;
  color: var(--primary-text-color);
  line-height: 120%;
  text-align: center;
}

.hero-subtitle {
  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 32px;
  color: var(--primary-text-color);
  line-height: 130%;
  text-align: center;
  font-style: italic;
}

.hero-images {
  position: relative;
  display: flex;
  /* overflow: hidden; */
  margin: 0 0 -24px calc(50% - 50vw);
}

.hero .card-image img {
  object-position: center;
}

.hero-images img {
  position: relative;
  display: block;
  aspect-ratio: 12.38;
  object-fit: contain;
  object-position: top left;
  width: 100%;
  height: 64px;
  align-self: stretch;
}
/* Text Style */

.title-text {
  font-family: var(--secondary-font);
  font-weight: 700;
  font-size: var(--secondary-font-size);
  color: var(--primary-text-color);
  line-height: 120%;
}

.primary-title-text {
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: var(--secondary-font-size);
  color: var(--primary-text-color);
  line-height: 120%;
}

.primary-text {
  line-height: 150%;
  font-weight: 450;
  font-family: var(--primary-font);
  color: var(--primary-text-color);
  font-size: var(--primary-font-size);
}

.primary-text.right {
  text-align: right;
}

.clamp,
.clamp2 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp2 {
  -webkit-line-clamp: 2;
}

.link-text {
  cursor: pointer;
  line-height: 150%;
  font-weight: 700;
  text-decoration: underline;
  font-family: var(--primary-font);
  color: var(--primary-text-color);
  font-size: var(--primary-font-size);
}

.subtitle-text {
  font-family: var(--primary-font);
  font-weight: 550;
  font-size: 20px;
  color: var(--primary-text-color);
  line-height: 120%;
}

.subtitle-text.bold {
  font-weight: 700;
  font-size: 24px;
}

.title-top {
  margin-top: -16px;
  z-index: 1;
  font-size: 24px;
}

.center {
  text-align: center;
}

/* Layout */
.fill {
  width: -webkit-fill-available;
}

.grid3x2,
.grid3x3 {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid3x3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid3x2.fill {
  grid-gap: 16px;
  justify-items: start;
  align-items: stretch;
  justify-content: space-between;
  align-content: space-between;
}

.grid3x3.fill {
  grid-gap: 16px;
  justify-items: start;
  align-items: stretch;
  justify-content: space-between;
  align-content: space-between;
}

.flex-auto,
.flex,
.flex4,
.flex8,
.flex16,
.flex24,
.flex32,
.flex64,
.row {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: start;
  flex-direction: row;
  align-content: flex-start;
}

.flex-auto {
  justify-content: space-between;
}

.flex4 {
  gap: 4px;
}

.flex8 {
  gap: 8px;
}

.flex16,
.row {
  gap: 16px;
}

.flex24 {
  gap: 24px;
}

.flex32 {
  gap: 32px;
}

.flex64 {
  gap: 64px;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.column-auto,
.column,
.column4,
.column8,
.column16,
.column24,
.column32,
.column64 {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: start;
  flex-direction: column;
  align-content: flex-start;
}

.column {
  gap: 1px;
}

.column-auto {
  justify-content: space-between;
}

.column4 {
  gap: 4px;
}

.column8 {
  gap: 8px;
}

.column16 {
  gap: 16px;
}

.column24 {
  gap: 24px;
}

.column32 {
  gap: 32px;
}

.column64 {
  gap: 64px;
}

.stretch {
  align-items: stretch;
}

.col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8,
.col9,
.col10,
.col11,
.col12 {
  position: relative;
  display: flex;
  align-self: stretch;
  overflow: hidden;
}

.col1 {
  width: calc(100% / (12 / 1));
}

.col2 {
  width: calc(100% / (12 / 2));
}

.col3 {
  width: calc(100% / (12 / 3));
}

.col4 {
  width: calc(100% / (12 / 4));
}

.col5 {
  width: calc(100% / (12 / 5));
}

.col6 {
  width: calc(100% / (12 / 6));
}

.col7 {
  width: calc(100% / (12 / 7));
}

.col8 {
  width: calc(100% / (12 / 8));
}

.col9 {
  width: calc(100% / (12 / 9));
}

.col10 {
  width: calc(100% / (12 / 10));
}

.col11 {
  width: calc(100% / (12 / 11));
}

.col12 {
  width: calc(100% / (12 / 12));
}
/* Buttons */
.primary-button,
.icon-button,
.round-button,
.btn {
  inset: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.primary-button {
  position: relative;
  display: flex;
  gap: 16px;
  font-weight: 600;
  line-height: 150%;
  padding: 18px 36px;
  border-radius: 8px;
  flex-direction: row;
  text-decoration: none;
  transition: ease-in-out 0.3s;
  justify-content: space-between;
  font-family: var(--primary-font);
  color: var(--tertiary-color);
  font-size: var(--primary-font-size);
  background-color: var(--secondary-color);
}

.link-box {
  display: flex;
  width: 100%;
  max-width: 366px;
  font-weight: 600;
  border-radius: 12px;
  align-items: center;
  padding: 10px 40px 10px 20px;
  color: var(--primary-color);
  justify-content: space-between;
  font-family: var(--primary-font);
  font-size: var(--primary-font-size);
  background-color: var(--tertiary-color);
}

.btn {
  padding: 12px 0;
  color: var(--tertiary-color);
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
  background-color: transparent;
}

.icon-button {
  position: relative;
  display: flex;
  flex-direction: column;
  appearance: none;
  background-color: #fff;
  color: var(--primary-color);
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  width: 56px;
  height: 56px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.round-button {
  position: relative;
  display: flex;
  appearance: none;
  cursor: pointer;
  width: 180px;
  height: 180px;
  margin: 0 auto;
  /* padding: 10px; */
  font-size: 64px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: var(--tertiary-color);
  background-color: var(--background-color);
  border: 4px solid var(--secondary-color);
}

.round-button.sm {
  width: 128px;
  height: 128px;
}

.round-button.secondary {
  background-color: var(--secondary-color);
}

.primary-button:hover,
.primary-button:focus {
  background-color: var(--background-color);
  box-shadow: 0 0 0 6px rgba(83, 22, 95, 0.25);
}

.primary-button.round {
  border-radius: 36px;
}
/* Video Player */

.video-player {
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 640px;
  height: 480px;
  min-height: 480px;
  max-height: 480px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.video-module_videoPosterPlayButton__CJm-m {
  background-color: var(--secondary-color) !important;
  color: #ffffff !important;
  border: none !important;
  width: 84px !important;
  height: 84px !important;
  font-size: 24px !important;
  box-shadow: 0 0 0 5px #63196b3d;
}

.video-module_videoOverlay__cbySQ {
  background-color: transparent !important;
}
/* Box */
.box,
.container {
  position: relative;
  display: flex;
  padding: 32px;
  flex: 1;
  z-index: 1;
  border-radius: 12px;
  align-items: stretch;
  flex-direction: column;
  text-decoration: none;
  background-color: var(--alternative-color);
}

.box.secondary-border-left {
  border-radius: 0;
  border-left: 4px solid var(--secondary-color);
}

.box.primary {
  background-color: var(--background-color);
}

.container.secondary {
  background-color: var(--secondary-color);
}

.container.primary {
  background-color: var(--primary-color);
}
/* Cards */

.card,
.reason-card {
  position: relative;
  display: flex;
  padding: 30px;
  flex: 1;
  border-radius: 24px;
  align-items: stretch;
  flex-direction: column;
  background-color: var(--secondary-color);
}

.card {
  background-color: var(--tertiary-color);
}

.card.noborder {
  border-radius: 12px;
}

.card .card-block {
  position: relative;
  flex-flow: column;
  display: flex;
  width: auto;
  grid: 24px;
  min-height: 196px;
  padding: 10px 20px;
  border-radius: 12px;
  align-items: start;
  justify-content: center;
  background-color: #ffffff;
}

.card .card-block .title-text {
  color: #d0adf2;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
}

.card .card-image,
.card .card-content {
  position: relative;
  display: flex;
  width: 100%;
}

.card .card-image {
  height: 180px;
}

.card .card-image.round-card {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  align-self: center;
  overflow: hidden;
}

.card .card-content {
  align-items: stretch;
  flex-direction: column;
}

.reason-card .reason-text {
  font-size: 32px;
  line-height: 120%;
}
/* Accordion */
.accordion {
  position: relative;
  display: flex;
  gap: 1px;
  width: 100%;
  overflow: hidden;
  flex-flow: column;
}

.column .accordion:first-child {
  border-radius: 4px 4px 0 0;
}

.column .accordion:last-child {
  border-radius: 0 0 4px 4px;
}

.accordion header {
  padding: 30px;
  cursor: pointer;
  background-color: #ebccf0;
}

.accordion .accordion-content {
  background-color: #ffe6f0;
}

.accordion .accordion-content p {
  padding: 30px;
}
/* Images */
.round-image {
  position: relative;
  display: block;
  width: 240px;
  height: 240px;
  overflow: hidden;
  border-radius: 50%;
}

.image-fluid {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.image-fluid.center {
  object-position: top center;
}

.image-fluid.contain {
  object-fit: contain;
}

/* Video  */
.video-container,
.pdf-container {
  position: relative;
  padding: 0;
  height: 640px;
  overflow: hidden;
  border-radius: 12px;
}

.pdf-container {
  width: 100%;
  height: 480px;
}

.video-container iframe,
.pdf-container iframe {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
/* Footer */

.footer {
  position: relative;
  display: flex;
  gap: 32px;
  padding: 64px 144px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--background-color);
  border-top: 1.5px solid #fff;
}

.footer-content {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  overflow: hidden;
}

.footer-brand {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 188px;
  height: 88px;
  /* align-self: stretch; */
}

.footer-link {
  position: relative;
  display: block;
  line-height: 150%;
  font-weight: 450;
  padding: 12px 16px;
  text-decoration: underline;
  font-family: var(--primary-font);
  color: var(--tertiary-color);
  font-size: var(--primary-font-size);
}

.footer-title {
  font-family: var(--secondary-font);
  font-weight: 700;
  font-size: 24px;
  color: var(--primary-text-color);
  line-height: 120%;
  letter-spacing: -0.25px;
  padding: 12px 16px;
}

.footer-divider {
  height: 1.5px;
  background-color: var(--tertiary-color);
}

/* Error */
.error-page {
  position: relative;
  display: flex;
  gap: 16px;
  padding: 64px 144px;
  flex-direction: column;
  align-content: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--tertiary-color);
}

.error-page .error-title {
  font-family: var(--secondary-font);
  font-weight: 700;
  color: var(--primary-color);
  font-size: 64px;
  line-height: 120%;
}

.error-page .error-text {
  line-height: 150%;
  font-weight: 500;
  color: var(--primary-color);
  font-family: var(--primary-font);
  font-size: var(--primary-font-size);
}

.error-page .error-button {
  position: relative;
  display: flex;
  gap: 16px;
  line-height: 150%;
  font-weight: 600;
  padding: 18px 36px;
  border-radius: 12px;
  flex-direction: row;
  align-content: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  text-decoration: none;
  color: var(--primary-color);
  font-family: var(--primary-font);
  font-size: var(--primary-font-size);
  border: 2px solid var(--primary-color);
}

/* Form */

.form-control {
  outline: 0;
  height: auto;
  padding: 16px;
  border-radius: 0;
  line-height: 150%;
  border-radius: 8px;
  transition: all 0.3s;
  color: var(--primary-color);
  border: 1px solid #53165f;
  font-family: var(--primary-font);
  font-size: var(--primary-font-size);
  background-color: var(--alternative-color);
}

.form-control:focus {
  outline-offset: 0px;
  border: 1px solid #53165f;
  outline: 3px #341148;
  box-shadow: 0 0 0 6px rgba(83, 22, 95, 0.25);
}

.form-control::placeholder {
  font-weight: 400;
  font-style: italic;
  color: #d16adc;
  font-family: var(--primary-font);
  font-size: var(--primary-font-size);
}

@media only screen and (max-width: 1024px) {
  .col1,
  .col2,
  .col3,
  .col4,
  .col5,
  .col6,
  .col7,
  .col8,
  .col9,
  .col10,
  .col11,
  .col12 {
    width: 100%;
  }

  .hero-title {
    font-size: 64px;
  }

  .flex-auto,
  .flex,
  .flex4,
  .flex8,
  .flex16,
  .flex24,
  .flex32,
  .flex64,
  .navbar .navbar-menu {
    flex-direction: column;
  }

  .navbar-menu {
    position: absolute !important;
    flex-flow: column;
    left: 0;
    right: 0;
    top: 100%;
    padding: 24px;
    overflow: hidden;
    box-shadow: none;
    margin: 0 0;
    align-items: stretch;
    border-radius: 0 0 12px 12px;
    background-color: var(--background-color);
    border: 2px solid var(--background-color);
    box-shadow: 0px 15px 12px 0px rgba(0, 0, 0, 0.5);
  }

  .navbar-menu .dropdown-menu {
    border-radius: 12px !important;
    box-shadow: none !important;
    position: relative !important;
    border: 2px solid var(--background-color);
  }
  .hero {
    padding: 180px 24px 0 24px;
  }
  .hero-images {
    margin: 0 12px -12px -12px;
  }
  .hero-images img {
    object-fit: cover;
  }

  .column-auto,
  .flex-auto {
    gap: 16px;
  }

  .section,
  .footer,
  .navbar {
    padding: 24px;
  }
  .grid3x2.fill,
  .grid3x3.fill {
    display: flex;
    flex-direction: column;
  }
}

/* Alert */
.success-message,
.error-message {
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;
  flex-direction: row;
  padding: 24px 48px;
  border-radius: 12px;
  justify-content: flex-start;
  background-color: var(--alternative-color);
}

.success-message {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  text-align: center;
}

.error-message {
  color: #af1010 !important;
  text-align: center;
  border: 2px solid #af1010;
  position: relative;
}

.error-message p {
  color: inherit !important;
}

.success-message > p,
.error-message p {
  font-weight: 600;
}
